<template>
	<div class="map">
		<input id="pac-input" class="controls" type="text" placeholder="Search Box"  v-if="isShow == false"/>
		<div id="mapCanvas" style="width: 800px;height: 400px;">

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			map: '', //地图
			geocoder: '', //地址解析
			marker: '',
			markersArray: [], //所有标记点数组
			location:''
		}
	},
	props: {
		isEdit: {
			type: Number,
			default: 1,
		},
		isShow: {
			type: Boolean,
			default: false,
		},
		new_location: {
			type: Object,
			default: function(){
				return {}
			},
		},
		
	},
	mounted() {
		console.log(this.isShow,'状态');
		this.geocoder = new google.maps.Geocoder(); //实例化地址解析
		if(this.isShow == false){
			console.log('获取当前');
			// console.log(this.new_location,'经纬度');

			this.getCurrentLocation();
		}else{
			// console.log(this.new_location,'经纬度');
			this.initMap(this.new_location)
		}
	},
	// watch:{
	// 	isShow(newVal){
	// 		if(newVal == true){
	// 			console.log(this.location);
	// 			this.placeMarker(this.location);
	// 		}
	// 	}
	// },

	methods: {
		// 获取当前位置
		getCurrentLocation() {
			this.$axios({
				method: 'post',
				// url: 'https://www.googleapis.com/geolocation/v/geolocate?key=AIzaSyDevl8IsKjfafUROmNwQp4BeVk9UPrL5bE',
				url: 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDevl8IsKjfafUROmNwQp4BeVk9UPrL5bE',
			}).then((res => {
				console.log(res,'当前位置');
				this.initMap(res.data.location)
				this.$emit('location',res.data.location)
			})).catch(err => {
				console.log(err)
			})
		},

		initMap(mapCenter) {
			console.log(mapCenter,'创建地图');

			// 创建地图实例
			this.map = ''
			this.map = new google.maps.Map(document.getElementById('mapCanvas'), { // 在map_canvas中生成一个实例地图
				center: mapCenter, // 中心点
				zoom: 12, // zoom是缩放比例，以中心点放大多少倍
				// mapTypeId: google.maps.MapTypeId.ROADMAP, //  地图展示的类型 
				language: "en",
			})
			this.placeMarker(mapCenter);
			google.maps.event.addListener(this.map, 'click', (event) => {
				this.$emit("changeIsEdit")
				this.placeMarker(event.latLng);
			});

			this.initAutocomplete();
		},
		// 地图上添加搜索框搜索
		initAutocomplete() {
			const input = document.getElementById("pac-input");
			console.log(input);
			const searchBox = new google.maps.places.SearchBox(input);
			console.log(searchBox,'搜索');

			this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
			// 将搜索框结果偏向当前地图的视口
			this.map.addListener("bounds_changed", () => {
				searchBox.setBounds(this.map.getBounds());
			});

			let markers = [];

			//侦听用户选择预测和检索时触发的事件  
			searchBox.addListener("places_changed", () => {
				const places = searchBox.getPlaces();

				if (places.length == 0) {
					return;
				}

				if (places.length == 1) {
					console.log("获取经纬度");
					console.log(places[0].geometry.location.lat());
					console.log(places[0].geometry.location.lng());
					console.log(places[0].geometry.location);

					// this.$emit('lat',places[0].geometry.location.lat())
					// this.$emit('lng',places[0].geometry.location.lng())
					let location = {
						lat:places[0].geometry.location.lat(),
						lng:places[0].geometry.location.lng()
					}
					this.$emit('location',location)

					this.placeMarker(location);
					return;
				}

				// 下面代码无用，
				// Clear out the old markers.
				markers.forEach((marker) => {
					marker.setMap(null);
				});
				markers = [];
				//
				const bounds = new google.maps.LatLngBounds();

				// 对于每个地方，获取图标、名称和位置。  
				places.forEach((place) => {
					if (!place.geometry || !place.geometry.location) {
						console.log("Returned place contains no geometry");
						return;
					}

					const icon = {
						url: place.icon,
						size: new google.maps.Size(71, 71),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(17, 34),
						scaledSize: new google.maps.Size(25, 25),
					};

					// Create a marker for each place.
					markers.push(
						new google.maps.Marker({
							map: this.map,
							icon,
							title: place.name,
							position: place.geometry.location,
						})
					);
					if (place.geometry.viewport) {
						// Only geocodes have viewport.
						bounds.union(place.geometry.viewport);
					} else {
						bounds.extend(place.geometry.location);
					}
				});
				this.map.fitBounds(bounds);
			});
		},

		// 给地图上添加标记
		placeMarker(location) {
			console.log(location,'给地图上添加标记');
			this.clearOverlays(this.infowindow);
			var marker = new google.maps.Marker({
				position: location,
				map: this.map,
			});
			this.markersArray.push(marker);
			//根据经纬度获取地址
			if (this.geocoder) {
				this.geocoder.geocode({
					'location': location
				}, (results, status) => {
					console.log(results,'地址')
					if (status == google.maps.GeocoderStatus.OK) {
						if (results[0]) {
							this.attachSecretMessage(marker, results[0].geometry.location, results[0]
								.formatted_address);
						}
					} else {
						alert("Geocoder failed due to: " + status);
					}
				});
			}
		},
		//在地图上显示经纬度地址
		attachSecretMessage(marker, piont, address, distance) {
			var message = "<b>address:</b>" + address;
			if (this.isEdit == 1) {
				this.$emit("getAddressEvent", address);
			}
			this.infowindow = new google.maps.InfoWindow({
				content: message,
				size: new google.maps.Size(50, 50)
			});
			this.infowindow.open(this.map, marker);
		},
		//删除所有标记阵列中消除对它们的引用
		clearOverlays(infowindow) {
			if (this.markersArray && this.markersArray.length > 0) {
				for (var i = 0; i < this.markersArray.length; i++) {
					this.markersArray[i].setMap(null);
				}
				this.markersArray.length = 0;
			}
			if (infowindow) {
				infowindow.close();
			}
		}

	}
}
</script>

<style scoped="" lang="less">
.map {
	position: relative;
}


#pac-input {
	background-color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	margin-left: 12px;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	min-width: 400px;
	display: flex;
	position: absolute;
	top: 57px !important;
	height: 35px;
	outline: none;
	border: none;
}
</style>
